<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <title>Contatti</title>
    <path
      d="M3.812 8.625s-.438 0-.438-.438.438-1.75 2.625-1.75 2.625 1.312 2.625 1.75-.438.438-.438.438H3.812zM6 6a1.31 1.31 0 0 0 1.312-1.312c0-.724-.588-1.312-1.312-1.312s-1.312.588-1.312 1.312S5.276 6 6 6zm6-4.5A1.5 1.5 0 0 0 10.5 0H3a1.5 1.5 0 0 0-1.5 1.5v.865H.516c-.285 0-.516.231-.516.516s.231.516.516.516H1.5v1.032H.516c-.285 0-.516.231-.516.516s.231.516.516.516H1.5v1.033H.516c-.285 0-.516.231-.516.516s.231.516.516.516H1.5v1.076H.516c-.285 0-.516.231-.516.516s.231.516.516.516H1.5v.865a1.5 1.5 0 0 0 1.5 1.5h7.5a1.5 1.5 0 0 0 1.5-1.5v-1.43c0-.51-.256-.959-.645-1.23.389-.271.645-.72.645-1.23v-1.43a1.49 1.49 0 0 0-.517-1.125c.315-.275.517-.674.517-1.125v-1.43zm-9 9.75a.75.75 0 0 1-.75-.75v-.865h.04c.285 0 .516-.231.516-.516s-.231-.516-.516-.516h-.04V7.527h.04c.285 0 .516-.231.516-.516s-.231-.516-.516-.516h-.04V5.462h.04c.285 0 .516-.231.516-.516s-.231-.516-.516-.516h-.04V3.398h.04c.285 0 .516-.231.516-.516s-.231-.516-.516-.516h-.04v-.865A.75.75 0 0 1 3 .751h6a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75H3zm8.25-.75a.75.75 0 0 1-.75.75h-.208a1.48 1.48 0 0 0 .208-.75V8.328c.398.02.75.341.75.743v1.43zm0-3.89c0 .401-.352.722-.75.743v-2.91c.39.032.75.345.75.738v1.43zm0-3.68c0 .393-.36.706-.75.738V1.5a1.48 1.48 0 0 0-.208-.75h0 .208a.75.75 0 0 1 .75.75v1.43z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {};
</script>
