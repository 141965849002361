<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${uid}`"
      :label="`${label}`"
      :label-for="`input-${uid}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid) ||
          (Object.keys(rules).includes('required_with') && !valid),
      }"
    >
      <!-- show a info tooltip with all selected options on hover, clickable too -->
      <template v-slot:label v-if="multiple && !taggable">
        <span
          :id="`tooltip-${id}`"
          style="cursor: pointer"
          @click="tooltip()"
          v-if="hasSel"
          ><b-icon icon="info-circle"
        /></span>
        {{ label }}
        <b-tooltip
          :show.sync="showTooltip"
          :target="`tooltip-${id}`"
          placement="top"
          v-if="hasSel"
        >
          {{ tltp }}
        </b-tooltip>
      </template>
      <!-- :custom-label="(opt) => options.find((x) => x.value == opt).text" -->
      <multiselect
        :class="getValidationStateClass({ dirty, validated, valid, errors })"
        :id="id"
        :name="`input-${uid}`"
        v-model="inputVal"
        :options="options.map((option) => option.value)"
        :custom-label="customLabel"
        :taggable="taggable"
        :multiple="multiple"
        :disabled="disabled"
        :closeOnSelect="closeOnSelect"
        :clearOnSelect="clearOnSelect"
        :searchable="true"
        @tag="doNotAddTag"
        :loading="isLoading"
        :placeholder="placeholder"
        :allow-empty="allowEmpty"
        selectLabel="[Invio]"
        :tag-placeholder="getDictionary('multiselect_no_results', 'form')"
        :selectedLabel="getDictionary('multiselect_selected', 'form')"
        deselectLabel="[Invio]"
        :aria-describedby="`input-${id}-live-feedback`"
        @select="onSelect"
        @remove="onRemove"
        @input="onInput"
        @search-change="asyncFind"
        :preselect-first="preselectFirst"
      >
        <template slot="noOptions">{{
          getDictionary("multiselect_no_options", "form")
        }}</template>
        <template slot="noResult">{{
          getDictionary("multiselect_no_results", "form")
        }}</template>
        <template
          v-if="multiple && !taggable"
          slot="selection"
          slot-scope="{ values, isOpen }"
          ><span class="multiselect__single" v-if="values.length && !isOpen"
            >{{ values.length }} opzioni selezionate</span
          ></template
        >
        <!-- lo slot tag fatto così rimuove i tag anche a tendina aperta -->
        <template v-if="multiple && !taggable" slot="tag" slot-scope="">{{
          ""
        }}</template>
      </multiselect>
      <template v-if="multiple && defaultHtmlMultipleSelect">
        <div class="mt-4">
          <b-button
            variant="light"
            class="mb-2"
            @click="toggleAdvancedSelection()"
            >{{
              advancedSelection
                ? "Nascondi selezione avanzata"
                : "Mostra selezione avanzata"
            }}</b-button
          >
        </div>
        <div class="mt-2" v-show="advancedSelection">
          <select
            class="default-html-multiple-select"
            multiple
            v-model="inputVal"
            :id="`defaultHtmlMultipleSelect_${id}`"
          >
            <option
              v-for="(option, index) in options"
              :value="option.value"
              :key="`${uid}_${index}`"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </template>
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
// import { showSnackbar } from "@/utils/messages";
import { getDictionary } from "@/utils/dictionary";
export default {
  props: {
    value: [],
    id: undefined,
    vid: String,
    name: String,
    label: String,
    options: Array,
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultHtmlMultipleSelect: {
      type: Boolean,
      default: false,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Seleziona",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      uid: null,
      advancedSelection: false,
      showTooltip: false,
    };
  },
  mounted() {
    this.uid = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`);
        return this.value ?? [];
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
    tggbl: {
      get() {
        return this.taggable;
      },
      set(val) {
        this.$emit("changeTaggable", val);
      },
    },
    tltp() {
      return Array.isArray(this.inputVal)
        ? this.inputVal
            .map((v) => this.options.find((o) => o.value === v).text)
            .join(",")
        : this.options.find((v) => v.value === this.inputVal).text;
    },
    hasSel() {
      return Array.isArray(this.inputVal)
        ? this.inputVal.length
        : this.inputVal;
    },
  },
  //   watch: {
  //     inputVal() {
  //         console.debug('watch', this.value)
  //         this.$emit('input', this.value);
  //     }
  //   },
  methods: {
    getDictionary,
    toggleAdvancedSelection() {
      this.advancedSelection = !this.advancedSelection;
      const is_taggable = this.advancedSelection ? false : true;
      this.tggbl = is_taggable;
    },
    tooltip() {
      this.showTooltip = !this.showTooltip;
    },
    customLabel(opt) {
      // opt => options.find(x => x.value == opt).text
      let found = this.options.find((x) => x.value == opt);
      return found ? found.text : "N/A";
    },
    doNotAddTag() {
      return false;
      // this.$showSnackbar({
      //   preset: "error",
      //   showAction: false,
      //   text: `Impossibile creare tag ${newTag}`,
      // });
      //   const tag = {
      //     text: newTag,
      //     value: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      //   };
      //   console.debug(`addTag: ${JSON.stringify(tag)}`)
      //   this.options.push(tag);
      //   this.inputVal.push(tag.value);
    },
    onSelect(selectedOption, id) {
      // console.debug("input onSelect", selectedOption, id);
      this.$emit("select", selectedOption, id);
    },
    asyncFind(query) {
      // console.debug("asyncFind: ", query);
      this.$emit("search-change", query);
    },
    onRemove(deletedOption, id) {
      // console.debug("input onRemove", deletedOption, id);
      this.$emit("remove", deletedOption, id);
    },
    onInput(value, id) {
      console.debug("input onInput", value, id);
      // this.$emit("input", value); // sembra che con questa emit, gli handler @input scattano 2 volte
    },
    // onRemove(removedOption, id) {
    //     console.debug(`onRemove option:${removedOption}, id: ${id} `)
    //     if(this.value.length === 0) this.value = []
    // },
    getValidationStateClass({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return "is-invalid";
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? ""
        : dirty || validated
        ? valid
          ? "is-valid"
          : "is-invalid"
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.multiselect__tags) {
  cursor: pointer;
}
</style>
