<template>
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height="18"
    width="18"
    viewBox="0 0 16 16"
    title="Indirizzo contraente diverso da indirizzo corrispondenza"
  >
    <title>Indirizzo contraente diverso da indirizzo corrispondenza</title>
    <path
      d="M14.556 6.548a.47.47 0 0 0 .472-.472h0c0-.261-.212-.472-.472-.472H9.827L13.271.761c.151-.213.101-.508-.111-.659s-.508-.101-.659.111l-3.833 5.39H1.444a.47.47 0 0 0-.472.472h0c0 .261.212.472.472.472h6.552L5.93 9.452H1.444a.47.47 0 0 0-.472.472c0 .261.212.472.472.472h3.814l-3.45 4.851c-.151.213-.101.508.111.659s.508.101.659-.111l3.84-5.399h8.139a.47.47 0 0 0 .472-.472c0-.261-.212-.472-.472-.472H7.09l2.066-2.905h5.401z"
      fill="#000"
    />
  </svg>
</template>

<script>
export default {};
</script>
