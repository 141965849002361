<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid) ||
          (Object.keys(rules).includes('required_with') && !valid),
      }"
      :data-disabled="disabled"
    >
      <!-- :ref="vid" -->
      <b-input-group class="mb-1">
        <b-form-input
          :id="`input-${id}`"
          v-model="viewVal"
          type="text"
          :ref="`ref-${id}`"
          :state="getValidationState({ dirty, validated, valid, errors })"
          :aria-describedby="`input-${id}-live-feedback`"
          placeholder="GG/MM/AAAA"
          @input="onTextInput"
          @blur="onTextBlur"
          autocomplete="off"
          :formatter="ten"
          :title="toLocale(inputVal, 'it')"
          :readonly="readonly"
          :disabled="disabled"
          @paste.prevent
        ></b-form-input>
        <b-input-group-append>
          <b-form-datepicker
            :id="`datepicker-input-${id}`"
            :name="`input-${id}`"
            :type="type"
            v-model="inputVal"
            show-decade-nav
            locale="it"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            :min="min"
            :max="max"
            button-only
            button-variant="lisaweb"
            right
            @context="onContext"
            @input="onInput"
            start-weekday="1"
            :disabled="disabled"
            :readonly="readonly"
            v-bind="labels['it'] || {}"
          ></b-form-datepicker>
        </b-input-group-append>
      </b-input-group>
      <!-- <p class="mb-1">Value: '{{ value }}'</p> -->
      <!-- <p class="mb-1">copyVal: '{{ copyVal }}'</p>
            <p class="mb-1">Selected: '{{ selected }}'</p>
            <p>Formatted: '{{ formatted }}'</p> -->

      <!-- <p>Value: '{{ inputVal }}'</p> -->
      <!-- :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" -->
      <!-- value-as-date -->
      <!-- ATTENZIONE: aggiungendo class="d-block" il msg di errore si vede. Il problema è che non è molto informativo.... -->
      <b-form-invalid-feedback
        :id="`input-${id}-live-feedback`"
        class="d-block"
        >{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>
<script>
import { toDate } from "@/utils/dates";

export default {
  props: {
    value: String,
    vid: String,
    name: String,
    label: String,
    readonly: {
      value: Boolean,
      default: false,
    },
    disabled: {
      value: Boolean,
      default: false,
    },
    type: {
      default: "text",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    min: null,
    max: null,
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
      formatted: "",
      selected: "",
      copyVal: "",
      modified: false, // when v-model has changed, "modified" turns to false so that copyVal shall be ignored
      labels: {
        it: {
          labelPrevDecade: "Decade Precedente",
          labelPrevYear: "Anno Precedente",
          labelPrevMonth: "Mese Precedente",
          labelCurrentMonth: "Mese Corrente",
          labelNextMonth: "Mese Successivo",
          labelNextYear: "Anno Successivo",
          labelNextDecade: "Decade Successiva",
          labelToday: "Oggi",
          labelSelected: "",
          labelNoDateSelected: "Nessuna Data Selezionata",
          labelCalendar: "Calendario",
          labelNav: "Navigazione",
          labelHelp: "Usare le frecce per navigare nel calendario",
        },
      },
    };
  },
  mounted() {
    this.id = this._uid;
    // convert backend value when it is a datetime: 1971-02-21T00:00:00.000000Z -> 1971-02-21
    this.inputVal = toDate(this.value);
    this.copyVal = this.toLocale(this.value, "it");
  },
  computed: {
    inputVal: {
      get() {
        // console.debug("get inputVal");
        return this.toLocale(this.value);
      },
      set(val) {
        // console.debug(`set inputVal=${val}.`);
        this.copyVal = this.toLocale(val, "it");
        this.modified = false; // v-model has changed...
        this.$emit("input", val);
      },
    },
    viewVal: {
      get() {
        // return this.value ? this.copyVal : ""; // useful on reset of v-model value
        return this.value
          ? this.modified
            ? this.copyVal
            : this.value.split("-").reverse().join("/")
          : ""; // useful on reset of v-model value
      },
      set(val) {
        console.debug(`set viewVal=${val}.`);
        this.copyVal = val;
        this.$emit("input", val);
        this.modified = true; // value has been manually set
      },
    },
  },
  methods: {
    ten(e) {
      return String(e).substring(0, 10);
    },
    toLocale(date, locale, defaultValue = "") {
      let ret;
      switch (locale) {
        case "it":
          ret = date ? date.split("-").reverse().join("/") : defaultValue;
          break;
        default:
          ret = date ? date.split("/").reverse().join("-") : defaultValue;
      }
      return ret;
    },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    // onBlur(e) {
    //     console.debug("input onBlur", e)
    //     this.$emit('blur', e)
    // },
    onTextBlur() {
      // console.debug("input onTextBlur", e);
      if (
        !this.viewVal ||
        (this.viewVal.length > 0 && this.viewVal.length < 10)
      ) {
        // this.modified = true; // value has been manually set
        // this.inputVal = "";
        // this.viewVal = "";
        // this.$emit("select", "");
        this.onTextInput("");
      }
    },
    onInput(date) {
      // console.debug("input onInput", date);
      if (date) this.copyVal = this.toLocale(date, "it");
      this.$nextTick(() => this.$refs[`ref-${this.id}`].$el.focus()); // focus will trigger the getValidationState
      this.$emit("select", date);
    },
    onTextInput(date) {
      // console.debug(`onTextInput date=${date}.`);
      this.modified = true; // value has been manually set
      let v = date;
      if (date.length > 10) {
        // console.debug("sono oltre i 10 caratteri");
        date = date.substring(0, 10);
        this.viewVal = date;
      } else {
        this.inputVal = date;
        if (v.match(/^\d{2}$/) !== null) {
          date = v + "/";
        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
          date = v + "/";
        }
        this.viewVal = date;
        if (date.length === 10 || date.length === 0) {
          this.inputVal = this.toLocale(this.viewVal);
          this.$emit("select", date);
        }
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>
