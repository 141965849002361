<template>
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height="24"
    width="24"
    viewBox="0 0 24 24"
    title="SEPA"
  >
    <title>SEPA</title>
    <path
      d="M12 22.75C6.072 22.75 1.25 17.928 1.25 12S6.072 1.25 12 1.25c.723 0 1.446.072 2.149.215a.75.75 0 0 1 .586.884.75.75 0 0 1-.885.585c-.604-.123-1.228-.185-1.851-.185C6.899 2.75 2.75 6.899 2.75 12s4.149 9.25 9.25 9.25 9.25-4.149 9.25-9.25a9.23 9.23 0 0 0-.186-1.851.751.751 0 1 1 1.471-.299c.143.703.215 1.426.215 2.149 0 5.928-4.822 10.75-10.75 10.75zM22.745 6.489c-.012-.837-.055-2.634-.234-3.649h-.001a1.65 1.65 0 0 0-.368-.767c-.023-.033-.037-.071-.066-.101a1.71 1.71 0 0 0-.916-.484h0c-1.015-.18-2.812-.223-3.649-.234a.75.75 0 0 0-.02 1.5l2.569.159-3.087 3.053a.75.75 0 1 0 1.056 1.066l3.109-3.074.108 2.547h0c.006.414.348.75.762.744a.75.75 0 0 0 .739-.76h0zm-9.017 10.226c-1.79-.069-3.327-1.231-3.885-2.911h-.979c-.377 0-.684-.306-.684-.683s.307-.684.684-.684h.681l-.021-.434.021-.437h-.681c-.377 0-.684-.307-.684-.684s.307-.684.684-.684h.978a4.25 4.25 0 0 1 3.876-2.911h.001c.602 0 1.194.141 1.722.408.163.081.285.222.342.395a.68.68 0 0 1-.648.901c-.107 0-.209-.024-.305-.072-.342-.172-.723-.263-1.103-.263a2.82 2.82 0 0 0-2.398 1.543h2.015c.377 0 .683.307.683.684s-.306.684-.683.684h-2.426a3.73 3.73 0 0 0 0 .871h1.978c.377 0 .684.307.684.684s-.307.683-.684.683H11.33c.461.907 1.373 1.501 2.408 1.544.372 0 .753-.091 1.093-.263.096-.048.201-.073.306-.073a.68.68 0 0 1 .612.379c.082.163.095.349.037.522a.68.68 0 0 1-.343.396 3.81 3.81 0 0 1-1.707.407h-.006z"
      fill="gray"
    />
  </svg>
</template>

<script>
export default {};
</script>
